<template>
  <div class="video-play">
    <van-sticky>
      <van-nav-bar left-arrow safe-area-inset-top @click-left="handleReturn">
        <template #title>
          <div class="head-title">我的视频</div>
        </template>
        <template #left>
          <van-icon name="arrow-left" color="#161719" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <main class="main">
      <div class="container_box ">
        <div class="video-box">
          <video
            :src="videoUrl"
            loop
            class="video_box"
            preload="auto"
            playsinline
            webkit-playsinline="true"
            x5-video-player-type="h5-page"
            x5-video-player-fullscreen="true"
            x5-playsinline
            x-webkit-airplay="allow"
            x5-video-orientation="portrait"
            :poster="require('../../../assets/userCard.png')"
            :playOrPause="playOrPause"
            @click="playOrPauseVideo"
          ></video>
          <img
            v-show="iconPlayShow"
            class="icon_play"
            @click="playvideo"
           :src="require('../../../assets/play.png')"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { reactive, toRefs, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import { searchFileList } from "@/api/file";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    onUnmounted(() => {
      state.playOrPause = true;
      playOrPauseVideo();
    });
    const state = reactive({
      iconPlayShow: true,
      playOrPause: false,
      videoUrl: "",
      cardId: route.params.cardId,
    });
    const loadVideo = async () => {
      try {
        Toast.loading({
          forbidClick: true,
          message: "加载中",
        });
        let video = await searchFileList({
          busiId: state.cardId,
          busiCat: "user",
          groupId: "descVideo",
        });
        if (video) {
          console.log("video", video);
          state.videoUrl = `${process.env.VUE_APP_WATCH_FILE_API}/${video[0].url}`;
        }
      } catch (error) {
        console.log(error);
      } finally {
        Toast.clear();
      }
    };
    loadVideo();

    const handleReturn = () => {
      router.back();
    };
    const playvideo = () => {
      let video = document.querySelectorAll("video")[0];
      state.iconPlayShow = false;
      video.play();
      state.playOrPause = true;
    };
    const playOrPauseVideo = () => {
      //暂停\播放
      let video = document.querySelectorAll("video")[0];
      if (state.playOrPause) {
        video.pause();
        state.iconPlayShow = true;
      } else {
        video.play();
        state.iconPlayShow = video.paused ? true : false;
      }
      state.playOrPause = !state.playOrPause;
    };
    return { ...toRefs(state), playvideo, playOrPauseVideo, handleReturn };
  },
};
</script>

<style lang="scss" scoped>
//.video_box{
//  width: 100%;
//  height: 100%;
//  //object-fit: contain !important;
//  object-fit: fill;
//}
.video-play {
  height: 100vh;
  .container_box {
    width: 100vw;
    height: calc(100vh - 46px);
    background: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: hidden;
  }
  video {
    //object-position: 0 0;
    //object-fit: fill !important;
    width: 100%;
    object-fit: contain !important;
    max-height: calc(100vh - 46px);
    overflow: hidden;

  }
  .video-box {
    position: relative;
    .icon_play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      height: 60px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
    }
  }
}
</style>
